var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "page-resources internal-page"
  }, [_vm.managerDeleteCategory ? _c('deleteResourceCategory', {
    attrs: {
      "bot-color": _vm.getterSelectedBot.botColor
    },
    on: {
      "callback": function callback(event) {
        return _vm.managerDeleteCategory = event;
      }
    }
  }) : _vm._e(), _c('categoryResourceDrawer', {
    attrs: {
      "bot-color": _vm.getterSelectedBot.botColor
    },
    on: {
      "callback": function callback(value) {
        return _vm.showResourcesDrawer = value;
      },
      "toDeleteItem": function toDeleteItem(item) {
        return _vm.managerDeleteCategory = true;
      }
    }
  }), _vm.showResourcesDrawer && Object.keys(_vm.getterSelectedCategoryResource).length > 0 ? _c('globalResourcesDrawer', {
    attrs: {
      "bot-color": _vm.getterSelectedBot.botColor
    },
    on: {
      "callback": function callback(value) {
        return _vm.selectedResource = value;
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "container-detailed-content",
    class: {
      'fixed-bar': _vm.fixedBar
    }
  }, [Object.keys(_vm.getterSelectedCategoryResource).length === 0 || Object.keys(_vm.getterSelectedResource).length === 0 ? _c('emptyView', {
    attrs: {
      "icon-path": "/img/icon-resources.svg",
      "icon-color": "#ff5326",
      "title": _vm.$t('pageResource.title'),
      "message": _vm.$t('pageResource.message')
    }
  }) : _vm._e(), Object.keys(_vm.getterSelectedCategoryResource).length > 0 && Object.keys(_vm.getterSelectedResource).length > 0 ? _c('section', {
    staticClass: "is-selected-item"
  }, [_c('resources', {
    staticClass: "container-box",
    attrs: {
      "selected-resource": _vm.selectedResource
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }