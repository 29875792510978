import { mapGetters, mapMutations } from 'vuex'

import {
  DeleteResourceCategory,
  CategoryResourceDrawer,
  EmptyView,
  Resources,
  GlobalResourcesDrawer,
  RightViewDrawer
} from '@/components'

export default {
  data () {
    return {
      managerDeleteCategory: false,
      fixedBar: false,
      selectedResource: {},
      showResourcesDrawer: false
    }
  },
  computed: {
    ...mapGetters([
      'getterSelectedBot',
      'getterSelectedCategoryResource',
      'getterSelectedResource'
    ])
  },
  components: {
    DeleteResourceCategory,
    CategoryResourceDrawer,
    EmptyView,
    Resources,
    GlobalResourcesDrawer,
    RightViewDrawer
  },
  beforeDestroy () {
    this.CLEAR_CATEGORIES_RESOURCE_STORE()
    this.CLEAR_RESOURCES_LESS_ALL()
  },
  methods: {
    ...mapMutations([
      'CLEAR_CATEGORIES_RESOURCE_STORE',
      'CLEAR_RESOURCES_LESS_ALL'
    ])
  }
}
